<template>
  <div class="mx-auto mt-2 txt-center">
    <div
      class="flex flex-row flex-wrap justify-center h-auto"
      id="selectorProduccion"
    >
      <div
        class="w-32 h-10 m-2 mt-2 text-center text-gray-400 border-2 border-gray-300 border-solid hover:bg-gray-200 hover:text-gray-600 font-oxygen"
      >
        <router-link to="/produccion-artistica/casi-un-destino">
          casi un destino
        </router-link>
      </div>

      <div
        class="w-32 h-10 m-2 mt-2 text-center text-gray-400 border-2 border-gray-300 border-solid hover:bg-gray-200 hover:text-gray-600 font-oxygen"
      >
        <router-link to="/produccion-artistica/mujeres">mujeres </router-link>
      </div>

      <div
        class="w-32 h-10 m-2 mt-2 text-center text-gray-400 border-2 border-gray-300 border-solid hover:bg-gray-200 hover:text-gray-600 font-oxygen"
      >
        <router-link to="/produccion-artistica/sociedad">sociedad </router-link>
      </div>

      <div
        class="w-32 h-10 m-2 mt-2 text-center text-gray-400 border-2 border-gray-300 border-solid hover:bg-gray-200 hover:text-gray-600 font-oxygen"
      >
        <router-link to="/produccion-artistica/suenios">sueños </router-link>
      </div>

      <div
        class="w-32 h-10 m-2 mt-2 text-center text-gray-400 border-2 border-gray-300 border-solid hover:bg-gray-200 hover:text-gray-600 font-oxygen"
      >
        <router-link to="/produccion-artistica/el-viaje">el viaje </router-link>
      </div>

      <div
        class="w-32 h-10 m-2 mt-2 text-center text-gray-400 border-2 border-gray-300 border-solid hover:bg-gray-200 hover:text-gray-600 font-oxygen"
      >
        <router-link to="/produccion-artistica/acuarelas"
          >acuarelas</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectorProduccion"
};
</script>

<style scoped>
.router-link-active {
  background: #e5e7eb;
  color: rgb(75, 85, 99);
  width: 100%;
  height: 100%;
  display: block;
}

#selectorProduccion a {
  height: 100%;
  display: block;
  vertical-align: middle;
  padding-top: 0.25rem;
}
</style>
