<template>
  <div class="grid grid-cols-1 p-5 my-5 bg-white">
    <vue-breadcrumb :breadcrumbs="$route.name" />
    <vue-selector-produccion
      :obra="$route.name !== 'home' ? $route.params.produccion : obra"
    />
    <vue-table
      :obra="$route.name !== 'home' ? $route.params.produccion : obra"
      :datos="info.obras"
      tipo="obras"
    />
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import SelectorProduccion from "@/components/SelectorProduccion";
import { mapState, mapActions } from "vuex";
//import Masonry from "@/components/Masonry";
import Table from "../components/Table";

export default {
  name: "ProduccionArtistica",
  props: {
    obra: String
  },
  components: {
    "vue-breadcrumb": Breadcrumb,
    "vue-selector-produccion": SelectorProduccion,
    //  "vue-masonry": Masonry,
    "vue-table": Table
  },
  computed: {
    ...mapState(["info"])
  },
  methods: {
    ...mapActions(["getInfo"])
  },
  mounted() {
    this.getInfo();
  }
};
</script>
