<template>
  <div class="mt-8">
    <div class="flex flex-wrap flex-row items-end gap-8">
      <div
        v-for="(item, index) in datos[obra]"
        :key="index"
        @click="showLightbox(item.name)"
      >
        <img
          :src="directorioThumbs + item.name"
          :alt="item.title"
          :key="index"
          loading="lazy"
        />
      </div>
    </div>
    <lightbox
      id="mylightbox"
      ref="lightbox"
      :directory="directorioObras"
      :images="datos[obra]"
      :timeoutDuration="5000"
    ></lightbox>
  </div>
</template>

<script>
import Lightbox from "vue-my-photos";

export default {
  name: "Table",
  props: ["obra", "datos", "tipo"],
  components: {
    Lightbox
  },
  data: function() {
    return {
      directorioObras:
        "https://cabubi.sfo2.cdn.digitaloceanspaces.com/brg/img/full",
      directorioThumbs:
        "https://cabubi.sfo2.cdn.digitaloceanspaces.com/brg/img/thumbs"
    };
  },
  methods: {
    showLightbox: function(imageName) {
      console.log(imageName);
      this.$refs.lightbox.show(imageName);
    }
  }
};
</script>
